.shopping_card {
  width: 200px;
}

.shopping_card_flex {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  padding: 20px 20px 20px 20px;
}

.card_img {
  object-fit: cover;
  height: 158px;
  width: 152px;
  position: relative;
}

.card_cover {
  position: relative;
}

.card_title_layout {
  display: flex;
  align-items: center;
  position: relative;
}

.card_title {
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  color: #2C2C2C;
}

.card_tag {
  background-color: #282828;
  border-radius: 2px;
  min-width: 60px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 4px;
  margin-left: 10px;
}

.tag_text {
  color: white;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}

.time {
  display: flex;
  align-items: center;
  font-size: 9px;
  color: #797979;
}

.time_text {
  margin-left: 5px;
  font-family: 'PingFang TC';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 20px;
  /* identical to box height, or 222% */
  letter-spacing: 1px;
  color: #797979;
}

.price {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #2c2c2c;

  display: flex;
  position: relative;
  align-items: center;
}

.detail_container {
  margin-bottom: 10px;
}

.household {
  font-style: normal;
  font-weight: 350;
  font-size: 12px;
  line-height: 24px;
  color: #797979;
}

.oid {
  font-style: normal;
  font-weight: 350;
  font-size: 12px;
  line-height: 24px;
  /* or 200% */
  color: #797979;
}

.download {
  display: flex;
  align-items: center;
}

.download_text,
.remove_text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  text-decoration-line: underline;
  color: #2C2C2C;}

.delete {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
}

.delete::before,
.delete::after {
  position: absolute;
  top: 9px;
  left: 3.5px;
  content: '';
  width: 13px;
  height: 2px;
  background-color: black;
}

.delete::before {
  transform: rotate(45deg);
}

.delete::after {
  transform: rotate(-45deg);
}

.disable {
  opacity: 0.7;
}

@media screen and (max-width: 420px) {
  .shopping_card {
    width: 180px;
  }

  .shopping_card_flex {
    display: grid;
    grid-template-columns: auto 1fr;
    width: 100%;
    padding: 10px 10px 10px 10px;
  }

}
