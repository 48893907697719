.layout {
  background-color: white;
  border-radius: 15px;
  padding: 24px 0px 15px 0px;
  box-sizing: border-box;
}

.edit_layout_pc {
  display: flex;
  justify-content: flex-end;
}

.edit_layout_mobile {
  display: none;
}

.edit_img {
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.edit_btn {
  display: flex;
  align-items: center;
  color: #4a4a4a;
  outline: none;
  background-color: transparent;
  border:none;
  padding: 0;
  cursor: pointer;
  font-size: 13px;
  line-height: 18px;
}

.content {
  display: flex;
  justify-content: space-between;
}

.avatar_layout {
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.avatar {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f5f9fd;
  border: 1px solid #D7E6F9;
}

.avatar_img {
  border-radius: 50%;
}

.avatar_change {
  position: absolute;
  right: 5px;
  bottom: 5px;
  cursor: pointer;
}

.form_layout {
  flex-basis: 500px;
}

.logout {
  text-align: center;
  margin-top: 30px;
}

.logout_text {
  cursor: pointer;
  color: #282828;
  font-size: 13px;
  line-height: 18px;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .layout {
    padding:24px;
  }

  .content {
    flex-direction: column;
    align-items: center;
  }

  .avatar_layout {
    margin-right: 0px;
  }

  .form_layout {
    flex-basis: initial;
  }

  .edit_layout_pc {
    display: none;
  }

  .edit_layout_mobile {
    display: initial;
    margin-left: auto;
  }
}