@media screen and (min-width: 768px) {
  .section {
    position: relative;
    height: calc(100%);
    padding-top: 60px;
    overflow: hidden;
  }
  
 
  .container {
    height: calc(100%);
    position: relative;
    display: grid;
    grid-template-columns: 1fr repeat(3, 26%) 1fr;
    grid-template-rows: 8% 1fr repeat(2, max-content) 3fr;
    grid-gap: 2% 2%;
    justify-items: flex-start;
  }

  .sectionNameContainer {
    grid-area: 1/2/2/5;
    display: flex;
    align-items: baseline;
    justify-self: flex-start;
  }
  
  .nameEg {
    font-size: 60px;
    font-weight: 100;
  }
  
  .nameCt {
    font-weight: 400;
    font-size: 18px;
    border-bottom: 1px solid #000;
    margin-left: 5px;
    padding-bottom: 1px;
  }
  
  .picContainer {
    width: 100%;
    max-width: 300px;
    max-height: 300px;
    position: relative;
    cursor: pointer;
    aspect-ratio: 1;
  }

  .picContainer1 {
    grid-area: 3/2/4/3;
  }

  .picContainer2 {
    grid-area: 3/3/4/4;
  }

  .picContainer3 {
    grid-area: 3/4/4/5;
  }

  .picContainer4 {
    grid-area: 4/2/5/3;
  }
  
  .picContainer5 {
    grid-area: 4/3/5/4;
  }

  .picContainer6 {
    grid-area: 4/4/5/5;
  }


  .ovalContainer {
    position: absolute;
    height: 20%;
    width: 10%;
    left: 10%;
    bottom: 71px;
  }
}

@media screen and (min-width: 1200px) {
  .section {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  .container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: 1fr auto auto 3fr;
    grid-gap: 2% 2%;
    justify-content: flex-start;
    height: 100%;
    padding: 0;
  }

  .sectionNameContainer {
    grid-area: 1/1/2/2;
    position: absolute;
    display: flex;
    align-items: baseline;
    left: 50px;
  }
  
  .nameEg {
    font-size: 60px;
    font-weight: 100;
  }
  
  .nameCt {
    font-weight: 400;
    font-size: 18px;
    border-bottom: 1px solid #000;
    margin-left: 5px;
    padding-bottom: 1px;
  }
  
  .picContainer {
    position: relative;
    height: 100%;
    max-height: 450px;
    max-width: 450px;
    cursor: pointer;
    margin: 20px 0 0 ;
  }

  .picContainer1 {
    grid-area: 3/1/4/2;
    transform: translateY(-35%);
  }

  .picContainer2 {
    grid-area: 2/ 2/ 3/ 3;
    transform: translateY(35%);
  }
  
  .picContainer3 {
    grid-area: 3/ 2/ 4/ 3;
    transform: translateY(35%);
  }

  .picContainer4 {
    grid-area: 2/ 3/ 3/ 4;
    transform: translateY(-5%);
  }

  .picContainer5 {
    grid-area: 3/ 3/ 4/ 4;
    transform: translateY(-5%);
  }

  .picContainer6 {
    grid-area: 2/ 4/ 3/ 5;
    transform: translateY(15%);
  }

  .ovalContainer {
    left: 5%;
    bottom: 71px;
    width: 5%;
  }
}

.section {
  overflow: hidden;
}

.picContainer::after {
  content: "";

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 50px;
  width: 50px;

  border-radius: 50%;

  background-image: url('/src/assets/icon_focus.svg');
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
  background-color: transparent;

  
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.picContainer:hover .pic {
  opacity: 0.3;
}

.picContainer:hover::after {
  opacity: 1;
}

.pic {
  object-fit: cover;
  transition: opacity .3s ease-in-out;
}

.show {
  display: block;
}

.noShow {
  display: none;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalImageContainer {
  position: relative;
  height: 60%;
  margin-bottom: 40px;
}

.modalImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}

.modalText {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  color: #000;
}

.closeBtnContainer {
  top: 0px;
  right: 0px;
  transform: translate(50%, -50%);
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 12px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.closeBtnContainer:hover {
  opacity: 0.7;
}

.pre {
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translate(-100%, -50%);
  background-image: url('../../../../../assets/fill_prev_white.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  border-radius: 50%;

  cursor: pointer;
}

.pre:hover {
  opacity: 0.7;
}

.next {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translate(100%, -50%);
  background-image: url('../../../../../assets/fill_next_white.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  border-radius: 50%;

  cursor: pointer;
}

.next:hover {
  opacity: 0.7;
}

.listContainer {
  width: 100%;
  overflow: auto;
  padding: 5px 0 ;
}

.imgList {
  display: flex;
  width: max-content;
  margin: 0 auto;
}

.chooseImg {
  height: 100px;
  width: 150px;
  margin: 10px 20px;
}

.select img{
  box-shadow: 0px 0px 1px 3px rgb(255, 0, 0);
}

.chooseImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 5px;
  cursor: pointer;
}

.ovalContainer {
  position: absolute;
  background-image: url('/src/assets/oval.png');
  background-size: contain;
  background-repeat: no-repeat;
}