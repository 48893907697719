.privacy {
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
}

.topic {
  color: #333333;
  font-size: 30px;
  line-height: 42px;
  text-align: center;
  position: relative;
  margin-bottom: 50px;
}

.topic::after {
  content: '';
  width: 30px;
  height: 3px;
  background-color: #cccccc;
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
}

.content {
  display: flex;
  justify-content: center;
}

.form_layout {
  box-sizing: border-box;
  flex-basis: 450px;
  padding-top: 100px;
  padding-left: 33px;
  padding-right: 33px;
  padding-bottom: 10px;
}