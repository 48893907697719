.row {
  position: relative;
  display: flex;
  align-items: center;
}

.inputContainer {
  position: relative;
  flex: 1;
  background-color:#F4F4F4;;
  border-radius: 30px;
  color: #9C9C9C;
  align-items: center;

  padding: 4px 10px;
  border: 1px solid transparent;
}

.inputErrorContainer {
  position: relative;
  flex: 1;
  background-color:#F4F4F4;;
  border-radius: 30px;
  padding: 4px 10px;
  border: 1px solid #EC455A;
}

.phone {
  flex: 1;
}

.verify {
  position: absolute !important;
  right: 0px;
  top: 0px;
  background: black;
  border: 1px solid transparent;
  height: 100%;
  width: 70px;
  border-radius: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: white;
  cursor: pointer;
}

.resend {
  position: absolute;
  right: 0px;
  top: 0px;
  background: white;
  border: 1px solid black;
  border-radius: 30px;
  height: 100%;
  width: 70px;
  border-radius: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: black;
  cursor: pointer;
  white-space: nowrap;
}

.resend:disabled {
  border: 0px solid black;
}

.otpCheck {
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
}

.signup {
  text-align: center;
  color: #666666;
  font-size: 13px;
  line-height: 30px;
}

.otp_layout {
  margin-bottom: 53px;
}

.otp_content {
  display: flex;
}

.otp_input {
  flex: 1;
}

.otp_resend {
  flex-basis: 100px;
  height: 30px;
  margin-left: 10px;
}

.loginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: #282828;
  border-radius: 0;
  border: none;
  flex-basis: 300px;
  height: 50px;
  color: white;
  cursor: pointer;
}

.loginButton:disabled {
  background-color: #cccccc;
  color: #ffffff;
  cursor: not-allowed;
}

.loginButton:hover, .loginButton:focus {
  opacity: 0.7;
  background: #000;
  color: #ffffff;
}

.form_title {
  color: #666666;
  font-size: 13px;
  line-height: 31px;
  /* flex-basis: 100px;
  flex-shrink: 0; */
  text-align: left;
  margin-right: 4px;
}

.form_input {
  padding-left: 10px;
}

.form_select {
  padding-left: 10px;
  background-color: transparent;
}

.form_select:disabled {
  border: 1px solid transparent;
  background: initial;
  padding-left: 10px;
  text-overflow: ellipsis;
}

.email {
  flex: 1;
  /* max-width: 250px; */
}

.lastName {
  flex: 1;
  /* max-width: 110px; */
  margin-right: 10px;
}

.firstName {
  flex: 1;
  /* max-width: 110px; */
}

.male {
  margin-left: 15px;
  margin-right: 20px;
}

.female {
  margin-right: 16px;
}

.job {
  flex: 1;
  max-width: 110px;
}

.year {
  flex: 1;
  /* max-width: 80px; */
  margin-right: 20px;
}

.month {
  flex: 1;
  /* max-width: 80px; */
  margin-right: 20px;
}

.day {
  flex: 1;
  /* max-width: 80px;
  margin-right: 5px; */
}

.city {
  flex: 1;
  margin-right: 20px;
}

.region {
  flex: 1;
  margin-right: 20px;
}

.postalCode {
  flex: 1;
  /* max-width: 80px; */
 }

.address {
  flex: 1;
  margin-top: 19px;
  /* max-width: 250px; */
}

.privacy_layout {
  margin-top: 45px;
  margin-bottom: 20px;
}

.privacy {
  display: flex;
  align-items: flex-start;
}

.privacy_label {
  font-size: 13px;
  line-height: 22px;
  color :#2C2C2C;
  cursor: pointer;
  margin-bottom: 8px;
}

.privacy_popup {
  text-decoration: underline;
  font-weight: 400;
  cursor: pointer;
}

.checkbox_error {
  border: 1px solid #EC455A !important;
}

.sign_up_button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  flex-basis: 390px;
  height: 60px;
  color: #2C2C2C;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  border-radius: 30px;
}

.sign_up_button:disabled {
  background-color: #cccccc;
  color: #ffffff;
  cursor: not-allowed;
}

.sign_up_button:hover, .sign_up_button:focus {
  opacity: 0.7;
  background: #000;
  color: #ffffff;
}

