.wrapper {
  width: 100%;
  height: 100%;
}

#mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 9999;
}
