.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.customizeSlideEffect {
  transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
}

/* footer */

.footer {
  z-index: 5;
}

.privatePolicy {
  margin-left: 20px;
  margin-right: 10px;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (min-width: 992px) {
  .privacy {
    text-decoration: underline;
    margin-left: 10px;
    cursor: pointer;
  }
}