.popupBg {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 0;
}

.popup {
  position: fixed;
  display: grid;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 80% 1fr;
  z-index: 9999;
}

.carousel {
  position: relative;
  grid-area: 2/1/3/2;
  height: 100%;
  width: 100%;
}

.slide {
  height: 95%;
  width: 90%;
  background: #fff;
  margin: auto 0;
  border-radius: 25px;
}

.closeBtn {
  grid-area: 3/1/4/2;
  display: flex;
  height: 20px;
  width: 20px;
  margin: 10px 0 0;
  z-index: 1;
  object-fit: cover;
  justify-self: center;
  cursor: pointer;
  border-radius: 50%;
}

.closeBtn:hover {
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 66% max-content 1fr;
  }

  .slide {
    height: 95%;
    width: 66%;
    background: #F7F7F5;
    margin: auto 0;
    border-radius: 25px;
  }

  .closeBtn {
    width: 35px;
    height: 35px;
  }

  .slide_pre {
    position: absolute;
    top: 50%;
    left: 13.5%;
    right: auto;
    z-index: 10;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../assets/fill_prev_white.png');
    cursor: pointer;
  }

  .slide_next {
    position: absolute;
    top: 50%;
    right: 13.5%;
    left: auto;
    z-index: 10;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../assets/fill_next_white.png');
    cursor: pointer;
  }

  .hide {
    display: none;
  }
}
