.page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 1000;
}


.img_box {
  border-radius: 25px;
  max-width: 600px;
  height: 600px;
  margin: auto;
  position: absolute;
  inset: 40px;
  background-color: rgb(255, 255, 255);

  background-image: url(../../assets/pay_error_desktop.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
