.layout {
  width: 100%;
  height: 100%;
  display: block;
  min-height: 300px;
}

.link {
  text-decoration: none;
}
