/* .custom-bullet {
  opacity: 0.5;
  background-color: #dddddd;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 5px;
}
 */

.custom-bullet {
  background-color: #f5f5f5;
  width: 8px;
  height: 8px;
  border: 1px solid #2c2c2c;
  border-radius: 50%;
  margin-left: 5px;
}

/* .swiper-pagination-bullet-active .custom-bullet {
  width: 15px;
  height: 15px;
  background-color: #282828;
  margin-left: 5px;
  margin-right: 0;
  opacity: 1;
} */

.swiper-pagination-bullet-active .custom-bullet {
  background-color: #2c2c2c;
  width: 8px;
  height: 8px;
  border: 1px solid #2c2c2c;
  border-radius: 50%;
}


.swiper-pagination-bullet {
  opacity: 1;
}

.swiper-lazy:not(.swiper-lazy-loaded) {
  display: none;
}
