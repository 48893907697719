.curtain {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: #000;
  opacity: .5;
  z-index: 10;
}

.formContainer {
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0);

  width: min(100%, 428px);
  height: min(460px, 90%);

  display: flex;
  flex-direction: column;

  background-color: #fff;
  border-radius: 10px;
  padding: 25px 19px 0px;
  margin-bottom: 20px;
  opacity: 1;
  transition: bottom 1.2s cubic-bezier(0.860, 0.000, 0.070, 1.000);
  z-index: 11;
}

.noShow {
  bottom: -100%;
}

.dn {
  display: none;
}

.closBtn {
  position: absolute;
  top: 10px;
  right: 20px;

  cursor: pointer;
}

.closeIcon {
  height: 35px;
  width: 35px;
}

.header {
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 37px;
}

.note {
  font-size: 13px;
  font-weight: 400;
  line-height: 30px;
  color: #666;
  margin-bottom: 20px;
}

.formRow {
  margin-bottom: 20px;
}

.rowHeader {
  font-size: 13px;
  font-weight: 400;
  line-height: 30px;
  color: #666;
}

.inputGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.inputContainer {
  width: 100%;
  border-radius: 25px;
  background-color: #F5f5f5;
  border: 1px solid transparent;
  padding-left: 10px;
  height: 30px;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #9c9c9c;
}

.illegalInput {
  border: 1px solid #EC455A;
}

.illegalInput::placeholder {
  color: #EC455A;
}

.submitBtn {
  margin: auto 0 56px;
  width: 100%;
  height: 60px;
  background-color: #4385F5;
  border-radius: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

}

.submitBtnText {
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  letter-spacing: 1px;
}

.errorNote {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #EC455A;
}


@media screen and (min-width: 768px) {
  .formContainer {
    position: fixed;
    transform: translateX(0%);
    bottom: 0%;
    left: 0px;
    width: 428px;
    height: min(460px, 90%);
    opacity: 1;
    transition: bottom 1.2s cubic-bezier(0.860, 0.000, 0.070, 1.000);
    z-index: 11;
  }
  .noShow {
    bottom: -100%;
  }
}