.popupContainer {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 100%;
  grid-template-rows: 50% 50%;
}

.imgContainer .image {
  background: #fff;
  object-fit: cover;
}

.textContainer {
  display: grid;
  grid-template-rows: 20px max-content 20px 1fr 10px;
  grid-template-columns: 1fr 90% 1fr;
}

.title {
  grid-area: 2/2/3/3 ;
  font-weight: 600;
  font-size: 24px;
  line-height: 33.6px;
  letter-spacing: 1.71px;
}

.description {
  grid-area: 4/2/5/3;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  overflow-y: auto;
  padding-right: 10px;
  white-space: pre-wrap;
}

@media screen and (min-width:768px) {
  .popupContainer {
    grid-template-columns: 60% 40%;
    grid-template-rows: 100%;
  }
  
  .imgContainer img {
    background: #F7F7F5;
    border-radius: 25px 0 0 25px;
  }

  .textContainer {
    display: grid;
    grid-template-rows: 1fr max-content 40px 50% 1fr;
    grid-template-columns: 1fr 85% 1fr;
  }

}
