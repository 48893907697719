.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f7f7f5;
  opacity: 0.9;
  padding: 5px 0px;
  color: #9c9c9c;
  font-size: 10px;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 375px) {
  .footer {
    font-size: 13px;
  }
}

@media screen and (min-width: 768px) {
  .footer {
    padding: 5px 40px;
    justify-content: flex-end;
  }
}