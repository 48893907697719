@import "utils";

.flex {
  display: flex;
  @include xs {
    flex-wrap: wrap;
    row-gap: 20px;
  }
}
.flex_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 26px auto 22px;
}
.flex_item {
  @include flex-column;
  position: relative;
  flex: 1 1 100px;
  @include xs {
    flex: 0 0 calc(50% - 10px);
  }
}
.info1 {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.flex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 70px;
  @include md {
    min-width: 50px;
  }
  @include xs {
    flex: 0 0 20px;
    min-width: unset;
  }
}
.info2 {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.flex_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 70px;
  @include md {
    min-width: 50px;
  }
  @include xs {
    display: none;
  }
}
.info3 {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.info4 {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
