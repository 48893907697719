.sectionContainer {
  position: relative;
  height: 100%;
  width: 100%;

}

.ytVideo {
  position: absolute;
  width: 100%;
  height: 100%;
}

.carouselContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.bgContainer {
  position: absolute;
  top: 20px;
  right: -20px;
  width: 50%;
  height: 100%;
}

.preBtnContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 15%;
  height: 75%;
  cursor: pointer;
}

.preBtnContainer:hover {
  opacity: 0.7;
}

.preBtn {
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translateY(-50%);
  z-index: 99;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../../../assets/fill_prev_white.png');
}

.nextBtnContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 15%;
  height: 75%;
  cursor: pointer;
}

.nextBtnContainer:hover {
  opacity: 0.7;
}

.nextBtn {
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translateY(-50%);
  z-index: 99;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../../../assets/fill_next_white.png');
}

.disabled {
  display: none;
}
