.sectionContainer {
  position: relative;
  height: calc(100% - 50px);
  width: 100%;
  padding-left: 50px;
  margin: 50px 0;
  overflow-y: hidden;
}

.sectionName {
  padding: 10px 0 20px 0;
}

/* bg setting */
.blueBar {
  position: absolute;
  left: -3%;
  bottom: 30px;
  height: 55%;
  width: 88%;
  background-color: #EEFCFF;
}

.oval {
  position: absolute;
  top: 13px;
  right: calc(50px + 60px);
  background-image: url('/src/assets/oval.png');
  width: 85px;
  height: 190px;
  background-size: contain;
  z-index: 999;
}

/* --end */



.gridContainer {
  width: 100%;
  height: calc(100% - 114px);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 5% 85% 10%;
}

.carouselContainer {
  grid-row: 2/3;
  position: relative;
  z-index: 1;
  margin-left: 40px;
}

.newsBg {
  aspect-ratio: 9/16;
  height: 100%;
  max-width: 450px;

  position: absolute;
  top: 20px;
  left: 60px;
  z-index: -1;
}

.cover {
  position: absolute;
  top: 0;
  left: 40px;
  aspect-ratio: 9/16;
  height: 100%;
  max-width: 450px;
}

.nextBtn {
  position: absolute;
  top: calc(50% - 20px);
  right: -65px;
  width: 40px;
  height: 40px;
  background-image: url('/src/assets/fill_next_white.png');
  background-size: contain;
  z-index: 1;
  cursor: pointer;
}

.nextBtn:hover {
  opacity: 0.7;
}

.preBtn {
  position: absolute;
  top: calc(50% - 20px);
  left: -50px;
  width: 40px;
  height: 40px;
  background-image: url('/src/assets/fill_prev_white.png');
  background-size: contain;
  z-index: 1;
  cursor: pointer;
}

.preBtn:hover {
  opacity: 0.7;
}

  .egName {
    font-weight: 100;
    letter-spacing: 2.5px;
    font-size: 60px;
    line-height: 84px;
  }

  .ctName {
    letter-spacing: .82px;
    font-size: 18px;
    line-height: 25.2px;
    border-bottom: 1px solid #000;
  }


