.container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  overflow: hidden;
  overscroll-behavior-y: none;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.9;
  z-index: 0;
}

.rotateAlert {
  width: 70%;
  height: 80%;
  object-fit: cover;
  object-position: 50%;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 8px 8px 15px 0px #0000001A;
}