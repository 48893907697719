.page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgb(84, 153, 251); */
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.img {
  background-image: url(../../assets/noService.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 80%;
  height: 80%;
  aspect-ratio: 1144 / 635;
  /* cursor: pointer; */
}

.mobile {
  width: 50%;
  height: 50%;
}
