.layout {
  max-width: 500px;
}

.edit_layout {
  display: flex;
  justify-content: flex-end;
}

.edit_img {
  margin-right: 5px;
}

.edit_btn {
  display: flex;
  align-items: center;
  color: #4a4a4a;
  font-size: 18px;
  outline: none;
  background-color: transparent;
  border:none;
  padding: 0;
  cursor: pointer;
}

.row {
  position: relative;
  display: flex;
  align-items: center;
}

.inputContainer {
  position: relative;
  flex: 1;
  background-color:#F4F4F4;;
  border-radius: 30px;
  padding: 4px 0px;
  color: #9C9C9C;
  border: 1px solid transparent;
}

.inputErrorContainer {
  flex: 1;
  background-color:#F4F4F4;;
  border-radius: 30px;
  padding: 4px 0px;
  border: 1px solid #EC455A;
}

.phone {
  font-size: 12px;
  line-height: 22px;
  margin-left: 20px;
  font-weight: bold;
  color: #2c2c2c;
}

.phone_bold {
  font-weight: 700;
  margin-left: 17px;
}

.logout {
  position: absolute;
  right: 0px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 30px;
  height: 35px;
  width: 35px;
  border-radius: 30px;
  font-weight: 400;
  font-size: 12px;
  color: #2C2C2C;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.form_title {
  color: #666666;
  font-size: 13px;
  line-height: 31px;
  /* flex-basis: 100px;
  flex-shrink: 0; */
  text-align: left;
  margin-right: 4px;
}

.form_input {
  padding-left: 15px;
  background-color: transparent;
}

.form_select {
  padding-left: 15px;
  background-color: transparent;
}

.form_select:disabled {
  border: 1px solid transparent;
  background: initial;
  padding-left: 0px;
  text-overflow: ellipsis;
}

.email {
  flex: 1;
  /* max-width: 250px; */
}

.lastName {
  flex: 1;
  /* max-width: 110px; */
  margin-right: 10px;
}

.firstName {
  flex: 1;
  /* max-width: 110px; */
}

.male {
  margin-left: 15px;
  margin-right: 20px;
}

.female {
  margin-right: 16px;
}

.job {
  flex: 1;
  max-width: 110px;
}

.year {
  flex: 1;
  /* max-width: 80px; */
  margin-right: 20px;
}

.month {
  flex: 1;
  /* max-width: 80px; */
  margin-right: 20px;
}

.day {
  flex: 1;
  /* max-width: 80px;
  margin-right: 5px; */
}

.city {
  flex: 1;
  margin-right: 20px;
}

.region {
  flex: 1;
  margin-right: 20px;
}

.postalCode {
  flex: 1;
  /* max-width: 80px; */
 }

.address {
  flex: 1;
  margin-top: 19px;
  /* max-width: 250px; */
}