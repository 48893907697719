/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";

$color_type_0: rgb(255, 255, 255);
$color_type_1: rgb(40, 40, 40);
$color_type_2: rgba(255, 255, 255, 0.49);

$font_type_0: 600 32px/1.37 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_1: 20px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_2: 16px/1.87 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_3: 12px/1.2 "roboto", Helvetica, Arial, serif;

.block {
  @include flex-column;
  background-color: $color_type_0;
}
.block_layout {
  position: absolute;
  overflow: hidden;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.picture {
  background: center center / cover no-repeat;
}
.picture_layout {
  position: absolute;
  top: -164px;
  bottom: -214px;
  left: -67px;
  right: -97px;
}
.block1 {
  @include flex-column;
  width: 100%;
  height: 100%;
}
.cover_block {
  @include flex-column;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255,255,255,0.25);
  }
.cover_block_layout {
  position: relative;
  height: min-content;
  width: 99.66%;
  max-width: 580px;
  margin: 1px auto;
}
.flex {
  @include flex-column;
}
.flex_layout {
  position: relative;
  height: min-content;
  margin: 35px 60px;
  @include sm {
    margin: 35px 30px;
  }
  @include xs {
    margin: 35px 13px;
  }
}
.flex_item {
  @include flex-column;
  position: relative;
}
.group {
  @include flex-column;
}
.group_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 0px auto 0px 0px;
}
.box2 {
  background-color: $color_type_0;
}
.box2_layout {
  position: absolute;
  top: 8px;
  height: 6px;
  left: 0px;
  right: -10px;
}
.subtitle2 {
  display: -webkit-box;
  overflow: hidden;
  @include font-face($font_type_3, $color_type_1, 0px);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 900;
}
.subtitle2_box_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 0px 0px;
}
.subtitle1 {
  display: -webkit-box;
  overflow: hidden;
  @include font-face($font_type_1, $color_type_1, 0px);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @include xs {
    font-size: 16px;
    text-align: left;
  }
  font-weight: 900;
}
.subtitle1_box {
  @include xs {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.subtitle1_box_layout {
  position: relative;
  height: min-content;
  width: 100%;
  margin: 0px 0px;
}
.flex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 8px;
}
.title {
  @include font-face($font_type_0, $color_type_1, 2px);
  @include xs {
    font-size: 24px;
    text-align: left;
  }
}
.title_layout {
  position: relative;
  height: min-content;
}
.content {
  @include font-face($font_type_2, $color_type_1, 1px);
  @include xs {
    font-size: 12px;
    text-align: left;
  }
}
.content_layout {
  position: relative;
  height: min-content;
  margin: 11px 0px 0px;
}

.diagram_box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 100px;
  height: 20px;
  background-color: #2c2c2c;
  opacity: 0.8;
  z-index: 10;

  font-size: 11px;
  line-height: 17px;
  color: #fff;
}
