.diagram_box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 30px;
    bottom: 20px;
    width: 100px;
    height: 20px;
    background-color: #2c2c2c;
    opacity: 0.8;
    z-index: 10;

    font-size: 11px;
    line-height: 17px;
    color: #fff;
  }
