.navBar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0px 30px;
  top: 0;
  left: 0;
  height: 56px;
  width: 100%;
  z-index: 10;
  background: transparent;
}

.noShow {
  display: none;
}

.preBtn {
  width: 12px;
  height: 12px;
  object-fit: contain;
  object-position: center;
}

.back_btn {
  margin-right: 10px ;
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImg {
  position: relative;
  left: 0;
  top: 0;
  width: 75px;
  height: 100%;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
}

.contentContainer {
  display: flex;
  align-items: center;
}

.serviceContainer {
  position: relative;
  height: 35px;
  width: 35px;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
  margin-left: 10px;
}

.notification {
  display: none;
  position: absolute;
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
}

.userNotification {
  top: 0;
  right: 0;
  transform: translate(-50%, -50%);
}

.salesNotification {
  top: 0;
  right: 0;
}

.show {
  display: block;
}

.toPickOutContainer {
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 35px;
  border-radius: 30px;
  background-color: black;
  display: flex;
}

.pickOutText {
  color: white;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  align-self: center;
}

.toCenterContainer {
  display: flex;
  align-items: center;
}

.memberImg {
  justify-content: center;
  align-items: center;
  display: flex;

  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-left: 10px;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
  background-color: #f5f9fd;
}

.name {
  color: #000;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 1px;
  padding-left: 5px;
}

@media screen and (min-width: 768px) {
  .navBar {
    height: 56px;
    padding: 0 80px;
    background: transparent;
  }

  .logoImg {
    position: relative;
    transform: translate(0, 0);
    height: 35px;
    width: auto;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
  }

  .memberImg {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 35px;
    width: 35px;
    margin-left: 10px;
  }

  .salesNotification {
    top: 0;
    right: 0;
  }
}