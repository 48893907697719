.row {
  position: relative;
  display: flex;
  align-items: center;
}

.inputContainer {
  position: relative;
  flex: 1;
  background-color:#F4F4F4;;
  border-radius: 30px;
  color: #9C9C9C;
  align-items: center;
  padding: 4px 10px;
  border: 1px solid transparent;
}

.inputErrorContainer {
  position: relative;
  flex: 1;
  background-color:#F4F4F4;;
  border-radius: 30px;
  padding: 4px 10px;
  border: 1px solid #EC455A;
}

.otp_layout {
  margin-bottom: 0px;
}

.otp_content {
  display: flex;
}

.otp_input {
  flex: 1;
}

.otp_resend {
  flex-basis: 100px;
  height: 30px;
  margin-left: 10px;
  background-color: #416EDE;
}

.login_btn {
  position: absolute !important;
  right: 0px;
  top: 0px;
  background: black;
  border: 0px solid transparent;
  border-radius: 30px;
  height: 100%;
  line-height: 1.5;
  width: 70px;
  border-radius: 30px;
  font-weight: 400;
  font-size: 12px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}


.login_btn:disabled {
  background-color: #cccccc;
  color: #ffffff;
  cursor: not-allowed;
}

.login_btn:hover, .login_btn:focus {
  opacity: 0.7;
  background: #000;
  color: #ffffff;
}

.form_title {
  margin-top: 20px;
  font-size: 13px;
  line-height: 30px;
  color: #666666;
  margin-bottom: 6px;
}

.seconds {
  color: #ec455a
  ;
}

.error_bottom {
  border-bottom: 1px solid #ee5c6e;
}

.submit {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
