.popupContainer {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 1fr auto 40px fit-content(75%) 2fr;
  grid-template-columns: 1fr 90% 1fr;
}

.titleContainer {
  grid-area: 2/2/3/3;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 33.6px;
  letter-spacing: 1.71px;
}

.subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  letter-spacing: 1.14px;
}

.description {
  grid-area: 4/2/5/3;
  padding-right: 10px;
  overflow-y: auto;
  
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 1px;
  white-space: pre-wrap;
}

@media screen and (min-width: 768px) {
  .popupContainer {
    grid-template-columns: 1fr 80% 1fr;
  }
}