.modal {
  position: relative;
  z-index: 11;
}

.outerContainer {
  position: fixed;
  bottom: 105px;
  left: 5px;
  width: 80px;
  height: 80px;
  z-index: 9;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.csBtnContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatting {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.unread {
  position: absolute;
  top: calc(50% + 15px);
  left: calc(50% + 15px);
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  background-color: #EC455A;
  border-radius: 50%;
}