.client {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  max-width: 1000px;
  height: 600px;
  z-index: 20;
}

.client div {
  width: 100%;
  height: 100%;
}

.mobile {
  width: 100%;
  height: 100%;
}

.minimal {
  width: 0;
  height: 0;
}