.sectionContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ytShow {
  display: block;
}

.ytHide {
  display: none;
}

.ytPopup {
  position: absolute;
  grid-area: 1/1/2/2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}


.ytBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.9;
  z-index: 0;
}

.contentContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ytContainer {
  position: relative;
  width: 80%;
  height: 80%;
}

.closeBtn {
  background-image: url('/src/assets/btn_close2@btnClose.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 35px;
  width: 35px;
  margin-top: 50px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

/* props */

.ytVideo {
  position: absolute;
  width: 100%;
  height: 100%;
}