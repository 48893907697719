.swiper-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
.swiper-wrapper {
 -webkit-transform-style: preserve-3d;
 transform-style: preserve-3d;
}

.swiper-button-prev {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-image: url('../../../../assets/fill_prev.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-button-prev:after {
  content: none;
}

.swiper-button-next {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-image: url('../../../../assets/fill_next.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-button-next:after {
  content: none;
}

@media screen and (min-width: 768px) {
  .swiper-button-prev, .swiper-button-next {
    width: 40px;
    height: 40px;
  }
}
