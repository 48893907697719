.sectionContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.customSlide {
  width: 80%;
}

.carouselContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0;
}

.bgContainer {
  position: absolute;
  top: 18px;
  right: -14px;
  width: 50%;
  height: 100%;
}

.preBtnContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 15%;
  height: 75%;
  cursor: pointer;
}

.preBtn {
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translateY(-50%);
  z-index: 99;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../../../assets/fill_prev_white.png');
  cursor: pointer;
}

.nextBtnContainer {
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translateY(-50%);
  right: 0;
  width: 15%;
  height: 75%;
  cursor: pointer;
}

.nextBtn {
  position: absolute;
  top: 50%;
  right: 20%;
  transform: translateY(-50%);
  z-index: 99;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../../../assets/fill_next_white.png');
  cursor: pointer;
}
