.button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: #282828;
  border-radius: 0;
  border: none;
  flex-basis: 300px;
  height: 50px;
  color: white;
  cursor: pointer;
}

.button:disabled {
  background-color: #cccccc;
  color: #ffffff;
  cursor: not-allowed;
}

.button:hover {
  opacity: 0.7;
}
