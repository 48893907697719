.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 31;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qrCodeContainer {
  padding: 50px;
  z-index: 10;
  background: #fff;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  border-radius: 21px;
}

.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 52px;
}

.show {
  display: flex;
}

.hide {
  display: none;
}