.header {
  width: 100%;
  grid-column: 1 / -1;
  font-weight: bold;
  margin-top: 28px;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
}

.header_text{
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */
  letter-spacing: 1px;
  color: #2C2C2C;
}

.switch {
  display: flex;
  flex-direction: row;
}

.wrapper_grid {
  display: grid;
  justify-content: start;
  /* justify-items: center; */
  align-content: center;
  grid-template-columns: repeat(4, 200px);
  grid-auto-flow: row;
  margin: 20px 10px;
  padding-bottom: 50px;
  grid-gap: 5px 5px;
  grid-area: 2/2/3/3;
}

.wrapper_flex {
  display: flex;
  flex-direction: column;
  width: 815px;
  grid-area: 2/2/3/3;
  grid-gap:  5px;
  margin: 20px 10px;
}

.icon {
  width: 20;
  height: 20;
}

.testIcon {
  width: 20;
  height: 20;
  border: 1px solid red;
}

@media screen and (max-width: 880px) {
  .wrapper_grid {
    grid-template-columns: repeat(3, 200px);;
  }
  .wrapper_flex {
    width: 610px;
  }
}

@media screen and (max-width: 640px) {
  .wrapper_grid {
    grid-template-columns: repeat(2, 200px);
  }

  .wrapper_flex {
    width: 405px;
  }
}

@media screen and (max-width: 420px) {
  .wrapper_grid {
    grid-template-columns: 1fr 1fr;
  }

  .header {
    width: 100%;
    grid-column: 1 / -1;
    font-weight: bold;
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
  }

  .wrapper_flex {
    width: 369px;
  }
}
