.popupContainer {
  width: 100%;
  height: 100%;
}

.popupContainer .popImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}
