.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show {
  display: flex;
}

.hide {
  display: none;
}

.outerContainer {
  width: 95%;
  height: 95%;
  z-index: 1;
  padding: 10px;
  
  border: 2px solid #979797;
  box-sizing: border-box;
  background: #fff;

}

.title {
  position: relative;
  font-weight: 700;
  font-size: 24px;
  line-height: 72px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.65);
}

.closeBtn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);  
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.contentContainer {
  padding: 20px 0 0;
  height: calc(100% - 93px);
  
}

.userDetailContainer {
  border-bottom: 1px solid #E9E9E9;
}

.detailTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 0 0 12px;
}

.userDetailGrid {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 5fr 2fr;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}

.userDetailGrid  .key {
  background: #F7F7F7;
}

.userDetailGrid  .value {
  padding: 10px 0;
  color:rgba(0, 0, 0, 0.65);
  white-space: normal;
}

.cartDetailContainer {
  height: calc(100% - 100px);
  padding: 25px 0 0;
}

.cartDetailBox {
  height: calc(100% - 34px);
  overflow-y: auto;
  padding-right: 15px;
}

.purchaseDetail {
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-gap: 10px 0;
  padding: 20px 5px ;

  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}

.purchaseDetail:nth-child(n) {
  border-bottom: 1px solid #E9E9E9;
}

.purchaseDetail:last-child {
  border-bottom: none;
}

.purchaseDetail .key {
  padding: 0 5px;
}

.purchaseDetail  .value {
  padding: 0px 5px;
  color:rgba(0, 0, 0, 0.65);
}



@media screen and (min-width: 768px) {
  .outerContainer {
    width: 712px;
    height: 693px;
    padding: 10px 20px;
  }

  .userDetailGrid {
    grid-template-columns: 1fr 3fr 2fr;
  }
  
  .userDetailGrid  .key {
    padding: 0 5px;
  }
  
  .userDetailGrid  .value {
    padding: 10px 5px;
  }
  
  .purchaseDetail {
    grid-template-columns: 2fr 5fr 2fr 5fr;
  }
}