.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* footer */

.footer {
  z-index: 99;
}

.privacy {
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
}

.action_btn_container {
  position: relative;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  height: auto;
  opacity: 1;
  z-index: 10;

}

.share_btns_container {
  z-index: -1;
  opacity: 0;
  transition: opacity .5s ease-in-out , z-index .1s .5s linear;
  pointer-events: none;
}

.share_btns_container_show {
  z-index: 1;
  opacity: 1;
  transition: opacity .5s ease-in-out , z-index .1s linear;
  pointer-events: auto;
}

.shareLink {
  width: 35px;
  height: 35px;
  background-image: url('../../../../assets/mobile_share.png');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 18px;
}
