.layout {
  display: flex;
  padding-right: 17px;
}

.input {
  flex: 1;
  height: 22px;
  outline: none;
  border: none;
  font-size: 12px;
  line-height: 22px;
  padding: 0px;
  background-color: transparent;
  width: 0px;
}

.input::placeholder {
  color: #9c9c9c;
}

.input:disabled {
  background: white;
  color: #9c9c9c;
}

.error {
  align-self: center;
  justify-self: center;
  color: #EC455A;
  font-size: 12px;
  border: 0px solid black;
}
